export function isOrg(o: any): o is POrg {
    return !!o && typeof o === 'object' && 'org_id' in o && !('space_id' in o);
}

export function isSpace(o: any): o is PSpace {
    return !!o && typeof o === 'object' && 'space_id' in o && !('app_id' in o);
}

export function isApp(o: any): o is PApp {
    return !!o && typeof o === 'object' && 'app_id' in o;
}

export function isOption(o: any): o is POption {
    return !!o && typeof o === 'object' && 'id' in o && 'status' in o && 'text' in o && 'color' in o;
}

export interface PUserStatus {
    "user": {
        "user_id": number
        /** The primary mail of the user */
        "mail": string,
        "status": "inactive" | "active" | "blacklisted",
        "locale": string,
        "timezone": "timezone",
        "created_on": string,
        "activated_on": string
    },
    "profile": {
        "profile_id": number,
        "name": string,
        "image"?: PImage
    },
}

export interface PImage {
    external_file_id?: string;
    file_id: number;
    hosted_by?: string | 'podio';
    hosted_by_humanized_name?: string;
    link: string;
    link_target?: string | '_blank';
    thumbnail_link: string;
    uuid_link?: string;
}

export interface POrg {
    org_id: number;
    name: string;
    spaces?: PSpace[];
    image?: PImage;
    logo?: number;
    premium?: boolean;
    rank: number;
    rights: string[];
    role: string | 'light';
    sales_agent_id?: number;
    segment?: any
    segment_size?: number;
    status: string | 'active';
    tier: string | 'premium';
    type: string | 'premium' | 'regular';
    url: string;
    url_label: string;
    user_limit: number;
}

export interface PSpace {
    space_id: number;
    name: string;
    apps?: PApp[];

    archived?: boolean;
    item_accounting_info?: any;
    premium?: boolean;
    push?: { channel: string; expires_in: number; signature: string; timestamp: number; rank: number };
    rank?: number;
    rights?: string[];
    role?: string | 'admin';
    sharefile_vault_url?: string;
    tier?: string | 'premium';
    type?: string | 'premium' | 'regular';
    top?: boolean;
    url?: string;
    url_label?: string;
}

interface PAppConfig {
    name: string;
    description?: string;
    allow_create?: boolean;
    allow_edit?: boolean;
    external_id?: string;
    icon?: string;
    icon_id?: number;
    item_name?: string;
    type?: string | 'standard';
    usage?: any;
    default_value?: any,
    unique?: boolean,
    hidden_create_view_edit?: boolean,
    required?: boolean,
    mapping?: any,
    label?: string,
    visible?: boolean,
    delta?: number,
    hidden?: boolean,
    settings?: {
        format?: 'html' | string,
        size?: 'large' | string
    }
}

export interface PApp {
    app_id: number;
    space_id: number;
    url?: string;
    config: PAppConfig;
    fields?: PField[];
    rights?: ('view_structure' | 'add_task' | 'view' | 'export' | 'delete' | 'subscribe' | 'add_widget' | 'share' | 'update' | 'add_item' | 'install' | 'add_integration' | 'add_hook' | 'add_flow' | 'reference' | 'manage_public_views')[];
}

export interface POption {
    id: number;
    status: 'active' | string;
    text: string;
    color: string;
}

export interface PField {
    field_id?: number;
    external_id?: string;
    status?: 'active' | 'deleted';
    label: string;
    type: PFieldType,
    config: {
        label: string;
        description?: string;
        /** order of field */
        delta?: number,
        visible?: boolean,
        required?: boolean,
        /** True if the field is not shown by default. Cannot be required at the same time. */
        hidden?: boolean,
        hidden_create_view_edit?: boolean,
        mapping?: 'meeting_time' | 'meeting_participants' | 'meeting_agenda' | 'meeting_location'
        type?: string;
        settings: {
            /** used for app/reference fields and category fields */
            multiple?: boolean,
            options?: POption[],
            script?: string,
            return_type?: 'text' | string,
            referenced_apps?: { app: PApp, app_id: number, view_id?: number, view?: any }[],
            [key: string]: any
        };
        [key: string]: any
    }
}

/**
 * @see https://developers.podio.com/doc/applications
 * @see https://help.podio.com/hc/en-us/articles/201019298-The-fields-in-app-templates
 */
export enum PFieldType {
    text = 'text',
    number = 'number',
    image = 'image', // : Holds an image.
    /** @deprecated */
    media = 'media', // : An embedded media typically from youtube or similar.
    date = 'date',  // TODO hours/end : A interval presented by a start and optional end date and optional time
    app = 'app', // : A reference to another app item.
    money = 'money', // : A currency value
    progress = 'progress', // : The progress of an app item.
    location = 'location', // : A location in f.ex. Google maps
    duration = 'duration', // : Stores are duration in seconds, but normally presented in hours, minutes and seconds.
    contact = 'contact', // : Reference to a contact, which can be either a user or a space (deprecated) contact.
    calculation = 'calculation', // : A calculation based on fields in the same or related apps
    embed = 'embed', // : A list of embeds (links).
    category = 'category', // : A list of options for the question'
    /** @deprecated */
    file = 'file', // : A list of files matching the configured mimetypes
    tel = 'tel', // : A telephone number
    email = 'email',
    tag = 'tag', // tags (in contact apps) (= organization??)
}


export interface PHook {
    status: 'active' | 'inactive',
    url: string,
    created_on: string,
    created_by: {
        type: string, // eg user,
        id: number
    },
    created_via: {
        url: null,
        auth_client_id: number,
        display: true,
        name: string,
        id: number
    },
    hook_id: number,
    type: string, // eg item.update
}

export interface PItem {
    item_id: number;
    fields: PItemField[];
    files?: PFile[];
}

export interface PItemField extends PField {
    values: any;
}

export interface PFile {
    name: string,
    /** not set for google drive / dropbox */
    size?: number,
    thumbnail_link?: string,
    link: string,
    created_on: string,
    mimetype?: string,
    external_file_id?: string,
    description?: string,
    file_id: number,
    created_by: any,
    hosted_by: 'podio' | 'google' | 'dropbox',
    created_via: any,
    hosted_by_humanized_name: string
}
